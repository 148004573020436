import { useWeb3Actions } from "@/web3/context";
import { useCallback } from "react";
import { logout } from "@/api/auth";

export default function useLogout(onLogout: () => void) {
  const { disconnect } = useWeb3Actions();

  return useCallback(async () => {
    await logout();

    disconnect();
    onLogout();
  }, [onLogout, disconnect]);
}
