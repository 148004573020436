import { Blockchain } from "@/shared/config/blockchain/types";
import { BrowserProvider, JsonRpcProvider, JsonRpcSigner } from "ethers";
import { Connector } from "../connectors/types";

export interface Web3ContextState {
  web3?: BrowserProvider | null;
  currentAddress?: string | null;
  network: Blockchain;
  chainId?: number | null;
  signer?: JsonRpcSigner | null;
  isCorrectChain?: boolean | null;
  isWalletPickerOpen: boolean;
  isNetworkMismatchPopupVisible: boolean;
  isAccountChangedPopupVisible: boolean;
  readOnlyProvider?: JsonRpcProvider | null;
  onSuccessfulNetworkChange: () => any;
}

export interface Web3ContextActions {
  connectToEthereum: (
    connector: Connector,
  ) => Promise<{ currentAddress: string; signer: JsonRpcSigner } | undefined>;
  disconnect: () => void;
  addChain: (data: NewChainData) => Promise<void>;
  switchChain: (chainId: string) => Promise<void>;
  toggleWalletPicker: () => void;
  toggleNetworkMismatch: (onSuccessfulChange?: () => any) => void;
  hideAccountChangePopup: () => void;
  switchBlockchain: (data: any) => void;
}

export enum ActionType {
  CONNECTED = "CONNECTED",
  CHAIN_CHANGED = "CHAIN_CHANGED",
  ACCOUNT_CHANGED = "ACCOUNT_CHANGED",
  DISCONNECTED = "DISCONNECTED",
  WALLET_PICKER_TOGGLE = "WALLET_PICKER_TOGGLE",
  NETWORK_MISMATCH_TOGGLE = "NETWORK_MISMATCH_TOGGLE",
  HIDE_ACCOUNT_CHANGE_POPUP = "HIDE_ACCOUNT_CHANGE",
  BLOCKCHAIN_CHANGED = "BLOCKCHAIN_CHANGED",
}

export interface Action {
  type: ActionType;
  payload?: any;
}

export interface NewChainData {
  chainId: string;
  chainName: string;
  nativeCurrency: any;
  rpcUrls: string[];
  blockExplorerUrls: string[];
}
