import { useQuery } from "@tanstack/react-query";
import notificationsApi from "@/api/notification/client";
import { DEFAULT_PAGE_SIZE } from "@/shared/constants";
import { OrderDirection } from "@/api/types";

const useGetNotifications = (
  take = DEFAULT_PAGE_SIZE,
  skip = 0,
  orderDirection = OrderDirection.DESC,
) => {
  return useQuery({
    queryKey: ["notifications", take, skip, orderDirection],
    queryFn: () =>
      notificationsApi.getNotifications(take, skip, orderDirection),
  });
};

export default useGetNotifications;
