import React from "react";
import { ThemedIcon } from "../Icon";

export interface Props {
  alt: string;
  width: number;
  height: number;
}

function Logo({ alt, width, height }: Props) {
  return (
    <ThemedIcon iconName="logo-v" alt={alt} width={width} height={height} />
  );
}

export default Logo;
