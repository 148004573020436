import { ICallableRequestBuilder } from "@/api/requestBuilder/types";
import config from "@shared/config/client";
import { withAuthenticatedClientRequest } from "../requestBuilder/withClientRequest";
import requests from "./requests";

const createLastSeenArtOrder =
  (request: ICallableRequestBuilder<any>) => () => {
    return request.call(`${config.apiUrl}/last-seen-art-order`, (init) => ({
      ...init,
      method: "POST",
    }));
  };

const clientRequests = {
  createLastSeenArtOrder: withAuthenticatedClientRequest(
    createLastSeenArtOrder,
  ),
  checkIfUnseenOrdersExist: withAuthenticatedClientRequest(
    requests.checkIfUnseenOrdersExist,
  ),
};

export default clientRequests;
