"use client";
import config from "@shared/config/client";
import React, { Fragment, useTransition } from "react";
import { BlockchainItem } from "../BlockchainItem";
import { twMerge } from "tailwind-merge";
import Cookies from "js-cookie";
import { useRouter } from "next/navigation";
import { Popover, Transition } from "@headlessui/react";
import ChevronIcon from "@/assets/icons/chevron.svg";
import { BLOCKCHAINS_MENU } from "./constants";
import { useWeb3Actions, useWeb3State } from "@/web3/context";
import { Blockchain } from "@/shared/config/blockchain/types";

const BlockchainSwitcher = ({
  toggleMobileSidebar,
}: {
  toggleMobileSidebar?: () => void;
}) => {
  const { network } = useWeb3State();
  const { switchBlockchain } = useWeb3Actions();
  const [, startTransition] = useTransition();
  const { refresh } = useRouter();

  const handleChange = (id: Blockchain) => {
    Cookies.set(config.cookies.blockchain, id);
    switchBlockchain(id);
    startTransition(refresh);
  };

  const currentItem = BLOCKCHAINS_MENU.find((item) => item.id === network)!;
  return (
    <Popover className="relative flex items-center">
      {({ open }) => (
        <>
          <Popover.Button className="relative inline-flex items-center outline-none">
            <BlockchainItem
              label={currentItem?.label}
              chainIcon={currentItem?.icon}
              labelClassName={"endemic:text-primary"}
            />
            <ChevronIcon
              className={twMerge(
                open ? "rotate-180" : "",
                "absolute fill-primary h-[6px] -right-1 bottom-[10px]",
              )}
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute rounded-2xl border-2 border-primary bg-primary z-50 endemic:border-invert endemic:bg-primary lg:top-8 top-8 left-[-18px] w-36">
              {({ close }) => (
                <Popover
                  onClick={() => close()}
                  className="flex flex-col gap-y-1 p-4"
                >
                  {BLOCKCHAINS_MENU.map((menu) => (
                    <button
                      key={menu.id}
                      onClick={() => {
                        handleChange(menu.id);
                        toggleMobileSidebar && toggleMobileSidebar();
                      }}
                    >
                      <BlockchainItem
                        label={menu.label}
                        chainIcon={menu.icon}
                        labelClassName={"endemic:text-invert"}
                      />
                    </button>
                  ))}
                </Popover>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default BlockchainSwitcher;
