import React from "react";
import { ThemedIcon } from "../Icon";

export interface Props {
  alt: string;
  className: string;
}

function Logo({ alt, className }: Props) {
  return <ThemedIcon alt={alt} iconName="logo" className={className} />;
}

export default Logo;
