"use client";
import { Button } from "../Button";
import ArrowIcon from "@/assets/icons/arrow-right.svg";
import { useWeb3Actions } from "@/web3/context";
import { useWalletConnect } from "./hooks";
import {
  ConnectorType,
  CONNECTORS,
  CONNECTOR_ICONS,
  CONNECTOR_LABELS,
} from "@/shared/constants";

interface Props {
  userId: string;
  className?: string;
  connector: ConnectorType;
  onConnect?: (address: string) => void;
  onError?: (errorMessage: string) => void;
  isWalletPicker?: boolean;
}

const WalletConnectButton = ({
  userId,
  connector,
  className,
  onConnect,
  onError,
  isWalletPicker,
}: Props) => {
  const { connectToEthereum, toggleWalletPicker, disconnect } =
    useWeb3Actions();
  const { connect } = useWalletConnect();
  const handleClick = async () => {
    if (isWalletPicker) {
      toggleWalletPicker();
    }

    try {
      const connection = await connectToEthereum(CONNECTORS[connector]);
      if (!connection) {
        return;
      }
      const user = await connect(
        userId,
        connection.currentAddress,
        connection.signer,
      );
      if (user?.address) {
        onConnect && onConnect(user.address);
      }
    } catch (err: any) {
      onError && onError(err.message);
      if (isWalletPicker) {
        toggleWalletPicker();
      }
      disconnect();
    }
  };

  return (
    <Button
      className={className}
      startIcon={CONNECTOR_ICONS[connector]}
      endIcon={<ArrowIcon className="fill-current w-4" />}
      onClick={handleClick}
      type="button"
    >
      {CONNECTOR_LABELS[connector]}
    </Button>
  );
};

export default WalletConnectButton;
