import { twMerge } from "tailwind-merge";

export interface Props {
  title: string;
  text: string | React.ReactElement;
  children: React.ReactNode;
  className?: string;
  childrenClassName?: string;
  error?: boolean;
  orientation?: "vertical" | "horizontal";
  id?: string;
}

const FormSectionContainer = ({
  title,
  text,
  children,
  className,
  childrenClassName,
  error,
  orientation = "horizontal",
  id,
}: Props) => {
  return (
    <div
      className={twMerge(
        "flex flex-col items-center w-full lg:items-start lg:flex-row py-10 lg:py-20 lg:pr-10 border-b border-[--color-bg-secondary]",
        orientation === "vertical" && "lg:flex-col",
        error && "border-t border-red",
        className,
      )}
      id={id}
    >
      <div
        className={twMerge(
          "flex flex-col gap-y-4 basis-[45%] mb-6 w-full ",
          orientation === "horizontal" && "lg:mr-32",
        )}
      >
        <h3>{title}</h3>
        <p className="text-base font-light lg:text-lg">{text}</p>
      </div>
      <div
        className={twMerge(
          "basis-[60%] w-full shrink-0 grow-0",
          childrenClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default FormSectionContainer;
