import { ReactElement } from "react";

export enum MenuItemType {
  SEPARATOR,
  ITEM,
  SUBITEM,
}

interface MenuItemBase {
  type?: MenuItemType;
  title?: string;
}

interface NavigationItemBase extends MenuItemBase {
  href: string;
  name: string | ReactElement;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  className?: string;
}

export interface NavigationMenuItem extends NavigationItemBase {
  type: MenuItemType.ITEM;
  subMenu?: SubMenuItem[];
  hideActiveIndicator?: boolean;
  target?: string;
}

export interface NavigationSubMenuItem extends NavigationItemBase {
  type: MenuItemType.SUBITEM;
  description?: string;
  image?: ReactElement;
  iconNameOnMobile?: string;
}

export type SubMenuItem = NavigationSubMenuItem | SeparatorSubMenuItem;
export interface SeparatorSubMenuItem extends MenuItemBase {
  type: MenuItemType.SEPARATOR;
}
