import {
  spreadArtistObject,
  spreadAuthorObject,
  spreadImageObject,
  spreadRelatedPosts,
  spreadVideoObject,
} from "./helpers";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { ReactNode } from "react";

export enum ContentTypes {
  BlogPost = "blogPost",
  Author = "author",
  Quote = "blogQuote",
}

export enum MediaType {
  VIDEO = "video",
  IMAGE = "image",
}

export enum PostCategoryType {
  News = "News",
  Interview = "Interview",
  ArtAndArtists = "Art & Artists",
  KnowHow = "Know-How",
}

export const POST_CATEGORY_SLUG = {
  [PostCategoryType.News]: "news",
  [PostCategoryType.Interview]: "interview",
  [PostCategoryType.ArtAndArtists]: "art-and-artists",
  [PostCategoryType.KnowHow]: "know-how",
};

export enum DisplaySections {
  Popular = "Popular post",
  LandingFeatured = "Landing page: Featured post",
  LandingRecommended = "Landing page: Recommended post",
  CategoryFeatured = "Category page: Featured post",
  CategoryRecommended = "Category page: Recommended post",
}

export class Author {
  constructor(entry: any, includedAssets: any[] = []) {
    const { fields } = entry;
    this.displayName = fields.displayName;
    this.avatar = spreadImageObject(includedAssets, fields.avatar);
    this.address = fields.address;
    this.shortBio = fields.shortBio;
  }
  displayName: string;
  avatar?: ContentfulImage;
  address: string;
  shortBio: ReactNode;
}

export class Artist {
  constructor(entry: any, includedAssets: any[] = []) {
    const { fields } = entry;
    this.name = fields.name;
    this.avatar = spreadImageObject(includedAssets, fields.avatar);
    this.shortBio = documentToReactComponents(fields.shortBio);
  }
  name: string;
  avatar?: ContentfulImage;
  shortBio: ReactNode;
}

export class ContentfulImage {
  constructor(entry: any) {
    const { fields } = entry;

    this.url = "https:" + fields.file.url;
    this.width = fields.file.details.width;
    this.height = fields.file.details.height;
    this.fileName = fields.file.fileName;
    this.title = fields.title;
    this.contentType = fields.file.contentType;
    this.type = MediaType.IMAGE;
  }

  url: string;
  width: number;
  height: number;
  fileName: string;
  contentType: string;
  type: MediaType;
  title?: string;
}

export class ContentfulVideo {
  constructor(entry: any) {
    const { fields } = entry;
    this.url = "https:" + fields.file.url;
    this.fileName = fields.file.fileName;
    this.contentType = fields.file.contentType;
    this.title = fields.title;
    this.type = MediaType.VIDEO;
  }
  url: string;
  fileName: string;
  contentType: string;
  type: MediaType;
  title?: string;
}

export class SimpleBlogPost {
  constructor(entry: any, includedAssets: any[] = []) {
    const { fields, sys } = entry;

    this.title = fields.title;
    this.slug = fields.slug;
    this.image = spreadImageObject(includedAssets, fields.image);
    this.category = fields.category;
    this.date = new Date(sys.createdAt);
  }

  title: string;
  slug: string;
  image?: ContentfulImage;
  category: PostCategoryType;
  date: Date;
}

export class BlogPost extends SimpleBlogPost {
  constructor(
    entry: any,
    includedEntries: any[] = [],
    includedAssets: any[] = [],
  ) {
    super(entry, includedAssets);
    const { fields } = entry;

    this.shortText = fields.shortText;
    this.author = spreadAuthorObject(
      includedAssets,
      fields.author,
      includedEntries,
    );
    this.artist = spreadArtistObject(
      includedAssets,
      fields.artist,
      includedEntries,
    );
    this.text = fields.text;
    this.video = spreadVideoObject(includedAssets, fields.video);
    this.isFeatured = fields.isFeatured;
    this.isHighlighted = fields.isHighlighted;
    this.display = fields.display;
    this.relatedPosts = spreadRelatedPosts(includedAssets, fields.relatedPosts);
  }

  shortText: string;
  text: any;
  author?: Author;
  artist?: Artist;
  video?: ContentfulVideo;
  isFeatured: boolean;
  isHighlighted: boolean;
  display: DisplaySections[];
  relatedPosts?: SimpleBlogPost[];
}

export class Quote {
  constructor(
    entry: any,
    includedAssets: any[] = [],
    includedEntries: any[] = [],
  ) {
    const { fields } = entry;

    this.text = fields.quote;
    this.artist = spreadArtistObject(
      includedAssets,
      fields.artist,
      includedEntries,
    );
    this.href = fields.quoteLink;
  }
  text: string;
  artist?: Artist;
  href?: string;
}
