import config from "@shared/config/client";
import { User } from "../users/types";
import { ClientRequestBuilder } from "../requestBuilder/clientRequestBuilder";

export async function getNonce(
  address: string,
  userId?: string,
  referralCode?: string | null,
) {
  const result = await fetch(`${config.apiUrl}/auth/nonce`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      address: address,
      userId,
      referralCode,
    }),
  });

  return await result.json();
}

export async function signIn(address: string, signature: string) {
  const result = await fetch(`${config.apiUrl}/auth/wallet/sign-in`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      address: address,
      signature,
    }),
  });

  return await result.json();
}

export async function connectWallet(
  userId: string,
  address: string,
  signature: string,
) {
  const request = new ClientRequestBuilder<User>();
  await request.authenticate();

  return await request.call(`${config.apiUrl}/auth/wallet/connect`, (init) => ({
    ...init,
    method: "POST",
    headers: {
      ...init.headers,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId,
      address,
      signature,
    }),
  }));
}
