import { SuccessData } from "@/app/(components)/Basic/SuccessModal/types";
import { ReactNode, useState } from "react";
import { SuccessModalContext } from "./context";
import ContextSuccessModal from "../ContextSuccessModal";
// import { withImagePreload } from "../helpers";

export interface Props {
  children: ReactNode;
}

export default function SuccessModalContextProvider({ children }: Props) {
  const [successData, setSuccessData] = useState<SuccessData | null>(null);

  // const setSuccessDataWithImagePreload = withImagePreload(setSuccessData);

  return (
    <SuccessModalContext.Provider
      value={{
        successData,
        setSuccessData,
      }}
    >
      {children}
      {successData ? (
        <ContextSuccessModal
          {...successData}
          onClose={() => setSuccessData(null)}
        />
      ) : null}
    </SuccessModalContext.Provider>
  );
}
