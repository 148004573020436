import { useState } from "react";
import useWsListener from "../hooks/useWsListener";
import { TxIndexerContext } from "./context";
import { IndexData } from "./types";

export interface Props {
  children: React.ReactNode;
}

export const TxIndexerProvider = ({ children }: Props) => {
  const [indexData, startIndexerSync] = useState<IndexData>({
    txHash: null,
    onIndexed: null,
  });

  useWsListener(indexData);

  return (
    <TxIndexerContext.Provider value={{ startIndexerSync }}>
      {children}
    </TxIndexerContext.Provider>
  );
};
