import template from "lodash/template";

export const getWalletLoginMessage = (address: string, nonce: string) => {
  return template(
    "Welcome to Endemic!\n\nClick to sign in!\n\nWallet address:\n<%= userAddress %>\n\nNonce:\n<%= nonce %>",
  )({
    userAddress: address.toLowerCase(),
    nonce,
  });
};

export const getWalletConnectMessage = (address: string, nonce: string) =>
  template(
    "Click to connect address!\n\nWallet address:\n<%= userAddress %>\n\nNonce:\n<%= nonce %>",
  )({
    userAddress: address.toLowerCase(),
    nonce,
  });
