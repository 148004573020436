"use client";
import useServiceMessage from "./hooks/useServiceMessage";
import NotificationsIcon from "@/assets/icons/bell.svg";

export interface Props {
  messageId: string;
}

const ServiceMessageBanner = ({ messageId }: Props) => {
  const { message } = useServiceMessage(messageId);

  if (!message) return null;

  return (
    <div className="px-10 p-4 w-full bg-red  flex items-center justify-center relative">
      <NotificationsIcon className="w-8 fill-white absolute left-5" />
      <span className="text-xl text-white pl-5">{message.text}</span>
    </div>
  );
};

export default ServiceMessageBanner;
