import { formatAddress } from "@/shared/helpers";
import { ShortUser, UserRoles } from "./types";
import { isAddress } from "ethers";
import { Blockchain } from "@/shared/config/blockchain/types";
import { Profile } from "@/app/(components)/Application/Profile/types";

export function addDisplayNamePrefix(displayName?: string | null) {
  return displayName ? `@${displayName}` : null;
}

export function getUsersName(
  user?: ShortUser,
  formatUserAddress?: boolean,
  displayEmail?: boolean,
) {
  const address = user?.address;

  return (
    user?.fullName ||
    addDisplayNamePrefix(user?.displayName) ||
    (address && formatUserAddress ? formatAddress(address) : address) ||
    (displayEmail && user?.email) ||
    "unnamed"
  );
}

export function createShortUser(address: string): ShortUser {
  return {
    id: address,
    address,
    hideProfile: true,
  };
}

export const getUsersHref = (user: Profile) => {
  const href = user.displayName
    ? addDisplayNamePrefix(user.displayName)
    : user.address || user.id;
  return `/${href}`;
};

export const sliceFullName = (fullName: string) => {
  const fullNameParts = fullName.split(" ");
  if (fullNameParts.length === 1) {
    return [fullName];
  }
  const lastName = fullNameParts.pop();
  return [fullNameParts.join(" "), lastName];
};

export const isValidUserPath = (userId: string) => {
  const UUID_REGEX =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
  return userId.startsWith("@") || isAddress(userId) || UUID_REGEX.test(userId);
};

export const isUserVerified = (user: ShortUser, blockchain: Blockchain) => {
  return !!user.blockchain?.includes(blockchain);
};

export const isUserArtist = (user: ShortUser) => {
  return user.role === UserRoles.ARTIST;
};
