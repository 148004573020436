import { useWeb3State, useWeb3Actions } from "@/web3/context";
import { useCallback } from "react";
import { Blockchain } from "../config/blockchain/types";
import config from "@/shared/config/client";
import { metamaskErrorToast } from "../errors";
import { decToHex } from "../utilities";

interface INativeCurrencyMapper {
  [blockchain: string]: {
    name: string;
    symbol: string;
    decimals: number;
  };
}

const NativeCurrencyMapper: INativeCurrencyMapper = {
  [Blockchain.POLYGON]: {
    name: "POL",
    symbol: "POL",
    decimals: 18,
  },
  [Blockchain.ETHEREUM]: {
    name: "Ether",
    symbol: "ETH",
    decimals: 18,
  },
  [Blockchain.ARBITRUM]: {
    name: "Arbitrum Ether",
    symbol: "AGOR",
    decimals: 18,
  },
};

export default function useChainSwitcher() {
  const { network } = useWeb3State();

  const { addChain, switchChain, toggleNetworkMismatch } = useWeb3Actions();

  return useCallback(
    async (onSuccessfulNetworkChange: any) => {
      const blockchainConfig = config.chains[network!];

      const chainId = blockchainConfig.id;

      const hexChainID = decToHex(chainId);

      try {
        await switchChain(hexChainID);

        toggleNetworkMismatch(onSuccessfulNetworkChange);

        // TEMP SOLUTION -> FIX: USE AWAIT TO HANDLE RACE CONDITION
        setTimeout(() => onSuccessfulNetworkChange?.(), 500);
      } catch (switchError: any) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.error.code === 4902) {
          await addChain({
            chainId: hexChainID,
            chainName: blockchainConfig.label,
            nativeCurrency: NativeCurrencyMapper[network!],
            rpcUrls: [blockchainConfig.rpcUrl],
            blockExplorerUrls: [blockchainConfig.etherscanUrl],
          });

          toggleNetworkMismatch(onSuccessfulNetworkChange);

          // TEMP SOLUTION -> FIX: USE AWAIT TO HANDLE RACE CONDITION
          setTimeout(() => onSuccessfulNetworkChange?.(), 500);
        } else {
          metamaskErrorToast(switchError.code);
        }
      }
    },
    [addChain, network, switchChain, toggleNetworkMismatch],
  );
}
