import queryString from "query-string";
import config from "@shared/config/client";
import { ICallableRequestBuilder } from "../requestBuilder/types";
import { DEFAULT_PAGE_SIZE } from "@/shared/constants";
import { OrderDirection } from "../types";
import { Notification } from "./types";

const getNotifications =
  (request: ICallableRequestBuilder<Notification[]>) =>
  async (
    take = DEFAULT_PAGE_SIZE,
    skip = 0,
    orderDirection = OrderDirection.DESC,
  ) => {
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/notifications`,
      query: {
        take,
        skip,
        orderDirection,
      },
    });

    return await request.call(url);
  };

const getNotificationsCount =
  (request: ICallableRequestBuilder<number>) => async () =>
    request.call(`${config.apiUrl}/notifications/count`);

const getUnreadNotificationsCount =
  (request: ICallableRequestBuilder<string>) => async () =>
    request.call(`${config.apiUrl}/notifications/unread-notifications-count`);

const requests = {
  getNotifications,
  getNotificationsCount,
  getUnreadNotificationsCount,
};

export default requests;
