import { getMediaType, MEDIA_TYPE } from "@/shared/media";
import { useMemo } from "react";
import EndemicImage from "../Image/EndemicImage";
import { ImagePlaceholder } from "../Image/types";

export interface Props {
  src: string;
  placeholder?: ImagePlaceholder | null;
  width?: number;
  height?: number;
  className?: string;
  containerClassName?: string;
  alt?: string;
  fill?: boolean;
  sizes?: string;
  grid?: boolean;
  type?: string;
  onClick?: () => void;
}

export default function ImageTypeHandler({
  src,
  placeholder,
  type,
  width,
  height,
  className,
  alt,
  fill,
  sizes,
  grid,
  onClick,
}: Props) {
  const mediaType = useMemo(() => type || getMediaType(src), [src, type]);

  return (
    <>
      {mediaType === MEDIA_TYPE.VIDEO ? (
        <video
          className={className}
          autoPlay
          width={width}
          height={height}
          loop
          muted
          playsInline
          onClick={onClick}
        >
          <source src={src} type="video/mp4" />
        </video>
      ) : (
        <EndemicImage
          displayDefaultPlaceholder
          src={src}
          placeholder={placeholder}
          alt={alt || ""}
          width={width}
          height={height}
          className={className}
          fill={fill}
          sizes={sizes}
          aspectRatio={!!width && !!height}
          dynamic={!!sizes}
          grid={grid}
          onClick={onClick}
        />
      )}
    </>
  );
}
