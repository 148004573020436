"use client";
import { useEffect, useState } from "react";
import {
  getLocalStorage,
  setLocalStorage,
} from "@/app/(components)/ThirdParty/helpers";
import { User } from "@/api/users/types";
import { useWeb3Actions } from "@/web3/context";
import QuestsStatusModal from "../../Quests/QuestsStatusModal";

export interface Props {
  user: User;
}

const SuccessfulLoginPopup = ({ user }: Props) => {
  const { toggleWalletPicker } = useWeb3Actions();
  const [showQuestModal, setShowQuestModal] = useState(false);

  const walletPickerShownKey = `is-wallet-picker-shown-${user.id}`;

  const handleModalOpening = () => {
    const userId = getLocalStorage(walletPickerShownKey, false);

    if (!userId && !user.address) {
      toggleWalletPicker();
      setLocalStorage(walletPickerShownKey, user.id);
    } else {
      setShowQuestModal(true);
    }
  };

  useEffect(() => {
    handleModalOpening();
  }, []);

  return showQuestModal ? <QuestsStatusModal user={user} /> : null;
};

export default SuccessfulLoginPopup;
