"use client";
import React, { useTransition } from "react";
import style from "./ThemeSelector.module.css";
import ThemeSelectorOption from "./ThemeSelectorOption";
import Cookies from "js-cookie";
import { Theme } from "@/shared/constants";
import { useRouter } from "next/navigation";
import { twMerge } from "tailwind-merge";
import config from "@shared/config/client";

export interface Props {
  classNames?: string;
  theme?: Theme;
}

function ThemeSelector({ classNames, theme = Theme.Light }: Props) {
  const [, startTransition] = useTransition();
  const { refresh } = useRouter();

  const handleChange = (theme: Theme) => {
    Cookies.set(config.cookies.theme, theme);
    startTransition(refresh);
  };

  return (
    <div className={twMerge("flex gap-1 items-center", classNames)}>
      {Object.values(Theme).map((_theme) => (
        <ThemeSelectorOption
          key={_theme}
          className={style[_theme]}
          active={theme === _theme}
          handleChange={() => handleChange(_theme)}
        />
      ))}
    </div>
  );
}

export default ThemeSelector;
