"use client";
import Script from "next/script";
import config from "@shared/config/client";
import { useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { pageview } from "./helpers";

const GoogleAnalytics = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (config.google.analyticsId) {
      const url = pathname + searchParams.toString();

      pageview(config.google.analyticsId, url);
    }
  }, [pathname, searchParams, config.google.analyticsId]);

  return (
    <>
      {config.google.analyticsId && (
        <>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${config.google.analyticsId}`}
          />
          <Script
            id="google-analytics"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('consent', 'default', {
                    'analytics_storage': 'denied'
                });
                
                gtag('config', '${config.google.analyticsId}', {
                    page_path: window.location.pathname,
                });
                `,
            }}
          />
        </>
      )}
    </>
  );
};
export default GoogleAnalytics;
