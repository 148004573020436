import { ICallableRequestBuilder } from "@/api/requestBuilder/types";
import config from "@shared/config/client";

const checkIfUnseenOrdersExist =
  (request: ICallableRequestBuilder<boolean>) => async () => {
    const url = `${config.apiUrl}/last-seen-art-order`;

    // This is hack because server returns boolean in typeof string
    const result = await request.call(url);
    return result.toString() === "true";
  };

const requests = {
  checkIfUnseenOrdersExist,
};

export default requests;
