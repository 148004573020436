"use client";
import { usePathname } from "next/navigation";
import { useEffect } from "react";
import { SEARCH_IMAGE_KEY } from "../constants";

const SearchRouteCleanup = () => {
  const path = usePathname();

  useEffect(() => {
    if (path !== "/search") {
      localStorage.removeItem(SEARCH_IMAGE_KEY);
    }
  }, [path]);

  return <></>;
};

export default SearchRouteCleanup;
