"use client";
import { twMerge } from "tailwind-merge";

interface Props {
  active: boolean;
  handleChange: () => void;
  className: string;
}
const ThemeSelectorOption = ({ active, handleChange, className }: Props) => {
  return (
    <span
      className={twMerge(
        "w-6 h-6 rounded-full border-dashed border-primary flex justify-center items-center",
        active && "border",
      )}
    >
      <span
        onClick={handleChange}
        className={twMerge(
          "cursor-pointer block w-3 h-3 rounded-full",
          className,
        )}
      ></span>
    </span>
  );
};

export default ThemeSelectorOption;
