import { getLevelCompletionPercentage } from "@/shared/helpers";
import { twMerge } from "tailwind-merge";

export interface Props {
  level: number;
  score: number;
  children: React.ReactNode;
  className?: string;
  pieColor?: string;
}

const LevelChartWrapper = ({
  level,
  score,
  children,
  className,
  pieColor,
}: Props) => {
  const chartProgress = {
    "--percentage": getLevelCompletionPercentage(level, score),
    "--color": pieColor || "",
  } as React.CSSProperties;

  return (
    <div
      style={chartProgress}
      className={twMerge(
        "w-[100px] h-[100px] relative flex items-center justify-center bg-[#f0f0f0] rounded-full before:content-[''] before:w-full before:h-full before:absolute before:rounded-full before:rotate-180 pie",
        className,
      )}
    >
      {children}
    </div>
  );
};

export default LevelChartWrapper;
