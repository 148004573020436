import config from "@shared/config/client";
import { ClientRequestBuilder } from "../requestBuilder/clientRequestBuilder";
import { withAuthenticatedClientRequest } from "../requestBuilder/withClientRequest";
import requests from "./requests";
import { ICallableRequestBuilder } from "../requestBuilder/types";

const createTipNotification = async ({
  from,
  to,
  amount,
  paymentErc20TokenAddress,
}: {
  from: string;
  to: string;
  amount: string;
  paymentErc20TokenAddress: string;
}) => {
  const request = new ClientRequestBuilder();

  await request.authenticate();
  await request.chainify();

  return request.call(
    `${config.apiUrl}/notifications/create-tip-notification`,
    (requestInit) => ({
      ...requestInit,
      method: "POST",
      headers: {
        ...requestInit.headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ from, to, amount, paymentErc20TokenAddress }),
    }),
  );
};

const markNotificationsAsSeen =
  (request: ICallableRequestBuilder<void>) =>
  async (notificationIds: string[]) => {
    return await request.call(
      `${config.apiUrl}/notifications/mark-as-seen`,
      (init) => ({
        ...init,
        method: "PUT",
        headers: {
          ...init.headers,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ids: notificationIds }),
      }),
    );
  };

const markAllNotificationsAsSeen =
  (request: ICallableRequestBuilder<void>) => async () => {
    return await request.call(
      `${config.apiUrl}/notifications/mark-all-as-seen`,
      (init) => ({
        ...init,
        method: "PUT",
        headers: {
          ...init.headers,
          "Content-Type": "application/json",
        },
      }),
    );
  };

const clientRequests = {
  createTipNotification,
  getNotifications: withAuthenticatedClientRequest(requests.getNotifications),
  getNotificationsCount: withAuthenticatedClientRequest(
    requests.getNotificationsCount,
  ),
  getUnreadNotificationsCount: withAuthenticatedClientRequest(
    requests.getUnreadNotificationsCount,
  ),
  markNotificationsAsSeen: withAuthenticatedClientRequest(
    markNotificationsAsSeen,
  ),
  markAllNotificationsAsSeen: withAuthenticatedClientRequest(
    markAllNotificationsAsSeen,
  ),
};

export default clientRequests;
