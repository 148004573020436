import { format, formatDistanceToNow, isAfter } from "date-fns";

export const formatDateWithMonth = (date: string | number) => {
  return format(new Date(date), "LLL d, yyyy");
};

export const formatDateWithHours = (date: string | number) => {
  return format(new Date(date), "LLL d, yyyy p");
};

export const formatCustom = (date: string | number, customFormat: string) => {
  return format(new Date(date), customFormat);
};

export const toSeconds = (date: Date) => {
  return Math.round(date.getTime() / 1000);
};

export const formatDate = (date: string, format: string) => {
  const dateObj = new Date(parseInt(date) * 1000);

  return dateObj.toLocaleDateString(format);
};

export const getTimeInYear = () => {
  const now = new Date();

  return Math.round(now.setFullYear(now.getFullYear() + 1) / 1000);
};

export const getTimeInSeconds = () => {
  const now = new Date();

  return Math.round(now.getTime() / 1000);
};

export const isExpiredInSeconds = (date: string | number | undefined) => {
  if (!date) {
    return false;
  }

  return isAfter(Date.now(), +date * 1000);
};

export const timeAgo = (date: Date) => {
  return formatDistanceToNow(new Date(date), { addSuffix: true });
};

export const getDaysInSeconds = (days: number) => {
  return days * 24 * 60 * 60;
};

export const extractDateAndTime = (date: string) => {
  return date.split(" ").reduce(
    (acc, curr) => {
      if (curr.includes(":") || curr.includes("PM") || curr.includes("AM"))
        return {
          date: acc.date,
          time: acc.time + curr,
        };
      return {
        date: `${acc.date} ${curr}`.trim(),
        time: acc.time,
      };
    },
    {
      date: "",
      time: "",
    },
  );
};
