import { CACHED_CONNECTOR_ID_KEY } from "@/web3/context/constants";
import config from "@shared/config/client";
import Cookies from "js-cookie";
import queryString from "query-string";

export async function signup(
  email: string,
  password: string,
  confirmPassword: string,
  acceptedTermsAndConditions: boolean,
  referralCode?: string,
) {
  const result = await fetch(`${config.apiUrl}/auth/sign-up`, {
    method: "POST",
    mode: "cors",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
      repeatPassword: confirmPassword,
      acceptedTermsAndConditions: acceptedTermsAndConditions,
      referralCode,
    }),
  });

  return result;
}

export async function credentialsSignIn(email: string, password: string) {
  const result = await fetch(`${config.apiUrl}/auth/sign-in`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  });
  if (result.status >= 400) {
    const res = await result.json();
    throw Error(res?.message);
  }
  return result.ok;
}

export async function logout() {
  Cookies.remove(CACHED_CONNECTOR_ID_KEY);

  const url = queryString.stringifyUrl({
    url: `${config.apiUrl}/auth/logout`,
  });
  await fetch(url, { credentials: "include" });
}

export async function verifyToken() {
  const result = await fetch(`${config.apiUrl}/auth/verify-token`, {
    credentials: "include",
    method: "GET",
  });

  return await result.json();
}

export async function verifyTokenWithAddress() {
  const result = await fetch(`${config.apiUrl}/auth/verify-token-address`, {
    credentials: "include",
    method: "GET",
  });

  return await result.json();
}
