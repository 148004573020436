import config from "@shared/config/client";
import { ICallableRequestBuilder } from "../requestBuilder/types";
import { ServiceMessage } from "./types";

const getActiveServiceMessage =
  (request: ICallableRequestBuilder<ServiceMessage>) => () => {
    return request.call(`${config.apiUrl}/service-messages/active`);
  };

const requests = {
  getActiveServiceMessage,
};

export default requests;
