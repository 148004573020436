import { useEffect } from "react";

interface Props {
  disable: boolean;
  breakpoint?: number;
}

const useDisableScroll = ({ disable, breakpoint }: Props) => {
  useEffect(() => {
    const isBelowBreakpoint = !!breakpoint
      ? window?.innerWidth < breakpoint
      : true;

    if (disable && isBelowBreakpoint) {
      document.body.style.overflowY = "hidden";
      return () => {
        document.body.style.overflowY = "unset";
      };
    }
  }, [disable, breakpoint]);
};

export default useDisableScroll;
