import { useMutation, useQueryClient } from "@tanstack/react-query";
import notificationsApi from "@/api/notification/client";
import { OrderDirection } from "@/api/types";

interface Props {
  userId: string;
}

const useMarkAllNotificationsAsSeen = ({ userId }: Props) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => notificationsApi.markAllNotificationsAsSeen(),
    onSuccess: () => {
      queryClient.invalidateQueries([
        "notifications",
        5,
        0,
        OrderDirection.DESC,
      ]);
      queryClient.invalidateQueries(["notifications", userId]);
      queryClient.invalidateQueries(["unread-notifications-count", userId]);
    },
  });
};

export default useMarkAllNotificationsAsSeen;
