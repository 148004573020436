"use client";
import { getNonce, connectWallet } from "@/api/auth/wallet";
import { useCallback } from "react";
import { getWalletConnectMessage } from "@/api/auth/helpers";
import { JsonRpcSigner } from "ethers";

export function useWalletConnect() {
  const connect = useCallback(
    async (userId: string, address: string, signer: JsonRpcSigner) => {
      if (!signer || !address || !userId) return;
      try {
        const { nonce } = await getNonce(address, userId);
        const message = getWalletConnectMessage(address, nonce);
        const signature = await signer.signMessage(message);
        const user = await connectWallet(userId, address, signature);

        return user;
      } catch (error: any) {
        if (error?.code === "ACTION_REJECTED" || error?.error?.code === 5000) {
          throw Error("User rejected");
        }
        throw Error(error.message);
      }
    },
    [],
  );

  return { connect };
}
