import {
  MenuItemType,
  NavigationMenuItem,
  NavigationSubMenuItem,
  SeparatorSubMenuItem,
} from "./types";

export const createNavigationItem = (
  item: Omit<NavigationMenuItem, "type">,
): NavigationMenuItem => {
  return {
    ...item,
    type: MenuItemType.ITEM,
  };
};

export const createNavigationSubItem = (
  item: Omit<NavigationSubMenuItem, "type">,
): NavigationSubMenuItem => {
  return {
    ...item,
    type: MenuItemType.SUBITEM,
  };
};

export const createNavigationSeparatorItem = (
  item: Omit<SeparatorSubMenuItem, "type">,
): SeparatorSubMenuItem => {
  return {
    ...item,
    type: MenuItemType.SEPARATOR,
  };
};
