"use client";
import Cookies from "js-cookie";
import { connectors } from "../connectors";
import { CACHED_CONNECTOR_ID_KEY } from "./constants";
import { Connector } from "../connectors/types";

export const clearCachedConnector = () => {
  Cookies.remove(CACHED_CONNECTOR_ID_KEY);
};

export const cacheConnector = (connector: Connector) => {
  Cookies.set(CACHED_CONNECTOR_ID_KEY, connector.id);
};

export const getCachedConnector = () => {
  const cachedConnectorId = Cookies.get(CACHED_CONNECTOR_ID_KEY);
  if (cachedConnectorId) {
    return connectors.find((c) => c.id === cachedConnectorId);
  }
  return null;
};
