"use client";
import { TxIndexerProvider } from "@/indexer/provider";
import { Theme } from "./constants";
import { NetworkMismatchPopup } from "@/app/(components)/Basic/NetworkMismatchPopup";
import Web3ContextProvider from "@/web3/context/provider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PropsWithChildren, useState } from "react";
import { ToastContainer, Zoom } from "react-toastify";
import { ThemeContext } from "./context/theme";
import { WalletChangePopup } from "@/app/(components)/Basic/WalletChangePopup";
import { Blockchain } from "./config/blockchain/types";
import ConnectWalletPopup from "@/app/(components)/Application/ConnectWalletPopup/ConnectWalletPopup";
import SuccessModalContextProvider from "@/app/(components)/Basic/SuccessModal/context";
import GuidedTourProvider from "@/app/(components)/Application/GuidedTour/context";

interface Props extends PropsWithChildren {
  theme?: Theme;
  network: Blockchain;
}

export default function Providers({
  theme = Theme.Light,
  network,
  children,
}: Props) {
  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 5 * 60 * 1000,
        },
      },
    }),
  );

  return (
    <ThemeContext.Provider value={{ theme }}>
      <GuidedTourProvider>
        <QueryClientProvider client={queryClient}>
          <Web3ContextProvider network={network}>
            <SuccessModalContextProvider>
              <TxIndexerProvider>
                <ToastContainer
                  position="bottom-center"
                  rtl={false}
                  autoClose={3000}
                  newestOnTop
                  transition={Zoom}
                />
                <NetworkMismatchPopup />
                <WalletChangePopup />
                <ConnectWalletPopup />
                {children}
              </TxIndexerProvider>
            </SuccessModalContextProvider>
          </Web3ContextProvider>
        </QueryClientProvider>
      </GuidedTourProvider>
    </ThemeContext.Provider>
  );
}
