import {
  Artist,
  Author,
  ContentfulImage,
  ContentfulVideo,
  SimpleBlogPost,
} from "./types";

export const getLinkById = (entries: any[], id: string) =>
  entries.find((e) => e.sys.id === id);

export const spreadVideoObject = (includedAssets: any, videoData: any) => {
  const video: ContentfulVideo =
    videoData?.sys &&
    new ContentfulVideo(getLinkById(includedAssets, videoData.sys.id));

  return video ? { ...video } : undefined;
};

export const spreadImageObject = (includedAssets: any, imageData: any) => {
  const image: ContentfulImage =
    imageData?.sys &&
    new ContentfulImage(getLinkById(includedAssets, imageData.sys.id));

  return image ? { ...image } : undefined;
};

export const spreadAuthorObject = (
  includedAssets: any,
  authorData: any,
  includedEntries: any,
) => {
  const author: Author =
    authorData?.sys &&
    new Author(getLinkById(includedEntries, authorData.sys.id), includedAssets);

  return author ? { ...author } : undefined;
};

export const spreadArtistObject = (
  includedAssets: any,
  artistData: any,
  includedEntries: any,
) => {
  const artist: Artist =
    artistData?.sys &&
    new Artist(getLinkById(includedEntries, artistData.sys.id), includedAssets);

  return artist ? { ...artist } : undefined;
};

export const spreadRelatedPosts = (
  includedAssets: any,
  relatedPostsData: any,
) => {
  const relatedPosts: SimpleBlogPost[] =
    relatedPostsData &&
    relatedPostsData.map((post: any) => ({
      ...new SimpleBlogPost(post, includedAssets),
    }));

  return relatedPosts;
};
