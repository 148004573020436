"use client";
import { Modal } from "@/app/(components)/Basic/Modal";
import React, { useEffect, useState } from "react";
import {
  setLocalStorage,
  getLocalStorage,
} from "@/app/(components)/ThirdParty/helpers";
import { Button } from "@/app/(components)/Basic/Button";
import questsApi from "@/api/quests/client";
import { useQuery } from "@tanstack/react-query";
import { getClasses, getQuestIcons } from "../helpers";
import { twMerge } from "tailwind-merge";
import { UserImageWithLevelChart } from "@/app/(components)/Basic/UserImageWithLevelChart";
import { getUsersName } from "@/api/users/helpers";
import ArrowRightIcon from "@/assets/icons/arrow-right.svg";
import { User } from "@/api/users/types";
import { tailwindColorToHexColor } from "@/shared/utilities";
import { QuestLink } from "../QuestLink";

export interface Props {
  user: User;
}

const QuestsStatusModal = ({ user }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data: completedQuestCount } = useQuery({
    queryKey: ["completed-quest-count", user.id],
    queryFn: () => questsApi.getCompletedQuestsCount(user.id),
  });

  const { data: totalQuestCount } = useQuery({
    queryKey: ["uncompleted-quest-count", user.id],
    queryFn: () => questsApi.getTotalQuestsCount(user.id),
  });

  const { data: firstUncompletedQuest } = useQuery({
    queryKey: ["first-uncompleted-quest", user.id],
    queryFn: async () => await questsApi.getFirstUncompletedQuest(user.id),
  });

  let textColor, questBorder, iconColor, backgroundColor, questIcon;

  if (firstUncompletedQuest) {
    const { icon } = getQuestIcons(
      firstUncompletedQuest!.type,
      firstUncompletedQuest!.color!,
      "w-[100px] h-[100px]",
      true,
    );

    const { textClass, questBorderClass, iconClass, bgClass } = getClasses(
      firstUncompletedQuest!.color!,
    );

    textColor = textClass;
    questBorder = questBorderClass;
    questIcon = icon;
    iconColor = iconClass;
    backgroundColor = bgClass;
  }

  const openModalAndStoreUserToLocalStorage = () => {
    const lastLoginTime = getLocalStorage(user.id, null);

    if (!lastLoginTime && firstUncompletedQuest) {
      setIsOpen(true);
      setLocalStorage(user!.id, Date.now());
      return;
    }

    const currentTime = Date.now();
    const timeDifference = currentTime - lastLoginTime;
    const hoursDifference = timeDifference / (1000 * 60 * 60);

    if (hoursDifference >= 24 && firstUncompletedQuest) {
      setIsOpen(true);
      setLocalStorage(user!.id, currentTime);
    }
  };

  useEffect(() => {
    openModalAndStoreUserToLocalStorage();
  }, [firstUncompletedQuest]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      className="w-[500px]"
      hasCloseButton
    >
      <div className="flex flex-col items-center gap-y-12">
        <div className="text-2xl font-bold">Daily quest summary</div>
        <UserImageWithLevelChart
          score={user.points!}
          level={user.level!}
          imageName={getUsersName(user)}
          imagePlaceholder={user.imagePlaceholder}
          imageClassName="w-20 h-20"
          image={{
            src: user?.imageUrl,
          }}
          levelClassName={backgroundColor}
          pieColor={tailwindColorToHexColor(
            firstUncompletedQuest?.color || "red",
          )}
        />
        <div>
          <div className="bg-gray-200 h-[14px] rounded-xl w-[300px]">
            <div
              className={twMerge("h-full rounded-xl", backgroundColor)}
              style={{
                width: `${(completedQuestCount! / totalQuestCount!) * 100}%`,
              }}
            ></div>
          </div>
          <div className="text-center mt-2 font-light">
            {completedQuestCount} of {totalQuestCount} quests completed
          </div>
        </div>
        <div>
          <div className="text-center mb-2 font-bold">Next quest</div>
          <QuestLink
            quest={firstUncompletedQuest!}
            overrideDefaultClass
            onClick={() => setIsOpen(false)}
          >
            <div
              className={twMerge(
                "flex items-center border rounded-3xl p-5 gap-x-3 cursor-pointer",
                questBorder,
              )}
            >
              <span>{questIcon}</span>
              <span className={twMerge("text-xl font-bold", textColor)}>
                {firstUncompletedQuest?.title}
              </span>

              <ArrowRightIcon className={twMerge("w-5 h-5", iconColor)} />
            </div>
          </QuestLink>
        </div>
        <Button
          type="link"
          onClick={() => setIsOpen(false)}
          href="/quests"
          endIcon={<ArrowRightIcon className="w-5" />}
        >
          View all quests
        </Button>
      </div>
    </Modal>
  );
};

export default QuestsStatusModal;
