"use client";
import { useWeb3State } from "@/web3/context";
import config from "@shared/config/client";
import { useCallback, useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import { IndexData } from "../provider/types";

export default function useWsListener({ txHash, onIndexed }: IndexData) {
  const { network } = useWeb3State();

  const [connect, setConnect] = useState<boolean>(false);

  const { sendMessage, readyState, getWebSocket } = useWebSocket(
    config.indexer.wsUrl,
    {
      onMessage: ({ data }) => {
        onIndexed(JSON.parse(data));
        setConnect(false);
      },
      retryOnError: true,
      share: true,
      reconnectInterval: 2000,
    },
    connect,
  );

  const handleSendMessage = useCallback(
    (txHash: string) => {
      setConnect(true);
      sendMessage(
        JSON.stringify({
          txHash,
          chainId: network,
        }),
      );
    },
    [network, sendMessage],
  );

  useEffect(() => {
    if (!txHash) return;

    handleSendMessage(txHash);

    return () => {
      if (readyState === WebSocket.OPEN) {
        const ws = getWebSocket();
        ws?.close();
      }
    };
  }, [getWebSocket, handleSendMessage, readyState, txHash]);
}
