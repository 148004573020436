"use client";

import React, { ReactNode } from "react";
import { HEADER_ANIMATION_OFFSET_Y } from "@/shared/constants";
import { twMerge } from "tailwind-merge";
import useScrollPosition from "@/shared/hooks/useScrollPosition";
import { ThemeSelector } from "@/app/(components)/Basic/ThemeSelector";
import { BlockchainSwitcher } from "@/app/(components)/Basic/BlockchainSwitcher";
import { Theme } from "@/shared/constants";

export interface Props {
  theme?: Theme;
  children: ReactNode;
}

const HeaderContentWrapper = ({ theme, children }: Props) => {
  const { scrollY } = useScrollPosition();
  const isSmall = scrollY > HEADER_ANIMATION_OFFSET_Y;

  return (
    <div className="lg:endemic:backdrop-blur-lg endemic:bg-green lg:endemic:bg-transparent bg-primary">
      <div className="hidden lg:flex justify-end items-center h-10 border-b night:border-opacityGray-100 endemic:border-opacityGray-100 gap-10 xl:px-12 px-6">
        <BlockchainSwitcher />
        <ThemeSelector theme={theme} />
      </div>
      <div
        className={twMerge(
          "w-full transition-all",
          isSmall ? "lg:py-3" : "lg:py-7",
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default HeaderContentWrapper;
