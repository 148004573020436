"use client";

import { useState } from "react";
import { SuccessData } from "./types";
import SuccessModal from "./SuccessModal";

export interface Props extends SuccessData {
  onClose?: () => void;
}

export default function ContextSuccessModal({ onClose, ...props }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleClose = () => {
    if (onClose) onClose();

    setIsOpen(false);
  };

  return <SuccessModal {...props} isOpen={isOpen} onClose={handleClose} />;
}
