import notificationsApi from "@/api/notification/client";
import { useQuery } from "@tanstack/react-query";

interface Props {
  userId: string;
}

const useGetUnreadNotificationsCount = ({ userId }: Props) => {
  return useQuery({
    queryKey: ["unread-notifications-count", userId],
    queryFn: () => notificationsApi.getUnreadNotificationsCount(),
  });
};

export default useGetUnreadNotificationsCount;
