import React from "react";
import { StaticImageData } from "next/image";
import UserImage from "../UserImage/UserImage";
import { ImagePlaceholder } from "../Image/types";
import { LevelChartWrapper } from "../../Layout/LevelChartWrapper";
import { twMerge } from "tailwind-merge";

export interface Props {
  image?: Partial<StaticImageData>;
  imagePlaceholder?: ImagePlaceholder;
  imageName?: string;
  level: number;
  score: number;
  imageClassName?: string;
  levelClassName?: string;
  pieColor?: string;
}

const UserImageWithLevelChart = ({
  imageName,
  image,
  imagePlaceholder,
  level,
  score,
  imageClassName,
  levelClassName,
  pieColor,
}: Props) => {
  return (
    <LevelChartWrapper level={level} score={score} pieColor={pieColor}>
      <UserImage
        name={imageName}
        image={image}
        imagePlaceholder={imagePlaceholder}
        rounded
        className="relative z-1"
        imgClassName={twMerge("bg-white", imageClassName)}
      />
      <div
        className={twMerge(
          "flex items-center justify-center w-10 h-10 bg-red absolute -bottom-2 text-white rounded-full text-lg text-center left-1/2 -translate-x-1/2",
          levelClassName,
        )}
      >
        {level}
      </div>
    </LevelChartWrapper>
  );
};

export default UserImageWithLevelChart;
