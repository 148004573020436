import { twMerge } from "tailwind-merge";

interface Props {
  position?: "top-right" | "top-left";
  size?: "big" | "small";
  className?: string;
  children?: React.ReactNode;
}

const NotificationIndicator = ({
  position,
  size = "big",
  className,
  children,
}: Props) => {
  return (
    <div
      className={twMerge(
        "bg-red rounded-full flex items-center justify-center",
        !!position && "absolute",
        position === "top-right" && "-top-1/2 left-1/2 translate-y-1/2",
        position === "top-left" && "-top-1/2 right-1/2 translate-y-1/2",
        size === "big" && "w-6 h-6",
        size === "small" && "w-2 h-2",
        className,
      )}
    >
      {children}
    </div>
  );
};

export default NotificationIndicator;
