"use client";

import { Notification } from "@/api/notification/types";
import { timeAgo } from "@/shared/dates";
import { ImageTypeHandler } from "@/app/(components)/Basic/ImageTypeHandler";
import DefaultProfile from "@/assets/graphics/default-profile.svg";
import DynamicLinkWrapper from "@/app/(components)/Basic/DynamicLinkWrapper";
import NotificationIndicator from "../../NotificationIndicator";

interface Props {
  notification: Notification;
}

const NotificationPopupItem = ({ notification }: Props) => {
  return (
    <DynamicLinkWrapper
      href={notification.href}
      wrapWithLink={!!notification.href}
      componentType="a"
    >
      <li className="px-4 py-4 pr-3 flex items-center border-b-[1px] border-endemicGray-500 gap-4">
        <div className="relative w-10 h-10 rounded-full shrink-0">
          {!!notification.notifier.imageUrl ? (
            <ImageTypeHandler
              src={notification.notifier.imageUrl}
              placeholder={notification.notifier.imagePlaceholder}
              alt="placeholder"
              className="rounded-full object-cover"
              fill
            />
          ) : (
            <DefaultProfile />
          )}
        </div>

        <div className="flex flex-col gap-1 mr-[14px]">
          <div
            className="text-[#A7A9AC] text-sm/[14px] [&_span]:text-primary [&_span]:endemic:text-invert  [&_a]:text-primary [&_a]:endemic:text-invert  [&_b]:text-primary [&_b]:endemic:text-invert"
            dangerouslySetInnerHTML={{ __html: notification.text! }}
          />
          <p className="text-endemicGray-700 text-xs/[12px] -tracking-[0.24px]">
            {timeAgo(notification.createdAt)}
          </p>
        </div>

        {!notification.isSeen && (
          <NotificationIndicator
            className="shrink-0 ml-auto my-auto"
            size="small"
          />
        )}
      </li>
    </DynamicLinkWrapper>
  );
};

export default NotificationPopupItem;
