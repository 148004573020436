"use client";
import { ReactNode, useState } from "react";
import { Step } from "react-joyride";
import GuidedTourContext from "./context";

interface Props {
  children: ReactNode;
}

export const GuidedTourProvider = ({ children }: Props) => {
  const [steps, setSteps] = useState<Step[]>([]);
  const [isTourOpen, setIsTourOpen] = useState(false);

  const startTour = (steps: Step[]) => {
    setSteps(steps);
  };

  const closeTour = () => {
    setSteps([]);
    setIsTourOpen(false);
  };

  return (
    <GuidedTourContext.Provider
      value={{ steps, isTourOpen, startTour, closeTour, setIsTourOpen }}
    >
      {children}
    </GuidedTourContext.Provider>
  );
};

export default GuidedTourProvider;
