import useChainSwitcher from "@/shared/hooks/useChainSwitcher";
import { useWeb3State, useWeb3Actions } from "@/web3/context";
import { Button } from "../Button";
import { Modal } from "../Modal";
import YellowEndyIcon from "@/assets/icons/endy/endy-yellow.svg";
import { resolveChainConfig } from "@/shared/config/blockchain/helpers";

export default function NetworkMismatchPopup() {
  const { isNetworkMismatchPopupVisible, onSuccessfulNetworkChange, network } =
    useWeb3State();

  const { toggleNetworkMismatch } = useWeb3Actions();

  const switchChain = useChainSwitcher();

  const onClose = () => {
    toggleNetworkMismatch();
  };

  const chaingConfig = resolveChainConfig(network);

  return (
    <Modal isOpen={isNetworkMismatchPopupVisible} onClose={onClose}>
      <div className="flex flex-col items-center justify-center gap-y-10">
        <YellowEndyIcon className="w-20 h-20 night:fill-white fill-black" />
        <div className="text-body-2 text-center lg:whitespace-nowrap">
          Please switch to{" "}
          <span className="font-bold">{chaingConfig?.label}</span> network
          within your wallet
        </div>
        <div className="flex flex-col gap-y-2 items-center">
          <Button onClick={() => switchChain(onSuccessfulNetworkChange)}>
            {`Switch to ${chaingConfig?.label}`}
          </Button>
          <Button onClick={() => toggleNetworkMismatch(onClose)}>Close</Button>
        </div>
      </div>
    </Modal>
  );
}
