import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export interface Props {
  chainIcon: ReactNode;
  label: string;
  labelClassName?: string;
}

const BlockchainItem = ({ chainIcon, label, labelClassName }: Props) => {
  return (
    <div className="flex items-center text-xs font-semibold text-primary leading-4 w-28">
      <div className={"mr-2"}>{chainIcon}</div>
      <div className={twMerge(`mr-auto`, labelClassName)}>
        {label.toUpperCase()}
      </div>
    </div>
  );
};

export default BlockchainItem;
