import notificationsApi from "@/api/notification/client";
import { useMutation } from "@tanstack/react-query";

const useMarkNotificationsAsSeen = () => {
  return useMutation({
    mutationFn: async ({ notificationIds }: { notificationIds: string[] }) =>
      notificationsApi.markNotificationsAsSeen(notificationIds),
  });
};

export default useMarkNotificationsAsSeen;
