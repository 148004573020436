import { twMerge } from "tailwind-merge";
import React, { useState } from "react";
import ArrowRightIcon from "@/assets/icons/arrow-right.svg";
import Link from "next/link";
import {
  MenuItemType,
  SeparatorSubMenuItem,
  NavigationMenuItem,
  NavigationSubMenuItem,
} from "../types";
import NavigationSeparator from "../NavigationSeparator";

export interface Props {
  menuItem: NavigationMenuItem;
  classes?: string;
  close: () => void;
}

const NavigationDropdownMenu = ({ menuItem, classes, close }: Props) => {
  const firstNonSeparatorSubMenuItem = menuItem.subMenu?.find(
    (item) => item.type !== MenuItemType.SEPARATOR,
  ) as NavigationSubMenuItem;

  const [descriptionForDisplay, setDescriptionForDisplay] =
    useState<NavigationSubMenuItem>(firstNonSeparatorSubMenuItem);

  return (
    <div
      className={twMerge(
        "absolute z-10 w-screen xl:max-w-2xl max-w-[500px]  rounded-2xl bg-primary border-2 border-primary endemic:border-invert endemic:text-invert -translate-x-1/4 left-1/2",
        classes,
      )}
    >
      <div className="bg-primary border-2 border-l-primary border-t-primary endemic:text-invert endemic:border-l-invert endemic:border-t-invert border-r-transparent border-b-transparent rounded-[3px] left-1/4 absolute rotate-45 w-5 h-5 -top-2.5"></div>
      <div className="group relative flex items-top xl:gap-x-6 gap-x-3  xl:p-5 p-3 leading-6">
        <div className=" flex-col">
          {menuItem.subMenu?.map((item: any, index: number) => {
            if (item.type === MenuItemType.SEPARATOR) {
              const separatorMenuItem = item as SeparatorSubMenuItem;
              return (
                <NavigationSeparator
                  text={separatorMenuItem.title}
                  key={index}
                />
              );
            } else {
              return (
                <Link
                  key={item.name}
                  href={item.href}
                  className="flex justify-between items-center p-3 rounded-lg xl:w-72 w-60  hover:bg-zinc-100 night:hover:bg-zinc-800"
                  onMouseOver={() => setDescriptionForDisplay(item)}
                  onClick={close}
                >
                  {item.name}
                  <ArrowRightIcon className="fill-primary endemic:fill-invert w-4" />
                </Link>
              );
            }
          })}
        </div>
        <div className="w-full">
          <div className="flex flex-none items-center justify-center xl:h-44 h-36 xl:mb-6 mb-4">
            {descriptionForDisplay?.image}
          </div>
          <div className="flex-auto">
            <p className="mt-1 text-2xl text-left mb-4 leading-7">
              {descriptionForDisplay?.title}
            </p>
            <p className="mt-1 text-lg text-left font-light leading-6">
              {descriptionForDisplay?.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavigationDropdownMenu;
