"use client";
import Link from "next/link";
import { useEffect, useState } from "react";
import { getLocalStorage, setLocalStorage } from "../../ThirdParty/helpers";

const CookieBanner = () => {
  const [cookieConsent, setCookieConsent] = useState<boolean>();

  useEffect(() => {
    const storedCookieConsent = getLocalStorage("cookie_consent", null);

    setCookieConsent(storedCookieConsent);
  }, [setCookieConsent]);

  useEffect(() => {
    const newCookieConsentValue = cookieConsent ? "granted" : "denied";

    if (typeof window.gtag !== undefined) {
      window.gtag("consent", "update", {
        analytics_storage: newCookieConsentValue,
      });
    }

    setLocalStorage("cookie_consent", cookieConsent);
  }, [cookieConsent]);

  return (
    <div
      className={`my-10 mx-auto max-w-max md:max-w-screen-sm
                      fixed bottom-0 left-0 right-0 z-50
                      flex px-3 md:px-4 py-3 justify-between items-center flex-col sm:flex-row gap-4  
                       bg-primary border-invert rounded-lg shadow ${cookieConsent !== null ? "hidden" : "flex"}`}
    >
      <div className="text-center text-primary endemic:text-invert">
        <Link href="/info/cookie-policy">
          <p>
            We use <span className="font-bold text-red">cookies</span> on our
            site.
          </p>
        </Link>
      </div>

      <div className="flex gap-2">
        <button
          className="px-5 py-2 text-primary endemic:text-invert"
          onClick={() => setCookieConsent(false)}
        >
          Decline
        </button>
        <button
          className="bg-red px-5 py-2 text-white rounded-lg"
          onClick={() => setCookieConsent(true)}
        >
          Allow Cookies
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
