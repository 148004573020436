"use client";

import React from "react";
import ActiveIcon from "@/assets/icons/section-title.svg";
import { ThemedIcon } from "@/app/(components)/Basic/Icon";
import { twMerge } from "tailwind-merge";
import useScrollPosition from "@/shared/hooks/useScrollPosition";
import { HEADER_ANIMATION_OFFSET_Y } from "@/shared/constants";

export interface Props {
  hideOnScroll?: boolean;
  className?: string;
}

function NavigationActiveItemIndicator({ hideOnScroll, className }: Props) {
  const { scrollY } = useScrollPosition();
  return (
    <span
      className={twMerge(
        `absolute -bottom-4 lg:-bottom-[46px] left-1/2  ${
          hideOnScroll && scrollY > HEADER_ANIMATION_OFFSET_Y && "hidden"
        }`,
        className,
      )}
    >
      <ActiveIcon className="absolute top-0 z-10 left-1/2 -translate-x-2/4 fill-red endemic:fill-yellow" />
      <ThemedIcon
        iconName="active-link-line"
        className="top-1 absolute -translate-x-2/4 w-[240px] h-[1px]"
      />
    </span>
  );
}

export default NavigationActiveItemIndicator;
