import { Notification } from "@/api/notification/types";
import NotificationPopupItem from "../NotificationPopupItem";

interface Props {
  notifications: Notification[];
}

const NotificationPopupList = ({ notifications }: Props) => {
  return (
    <ul className="flex flex-col">
      {notifications.map((notification) => (
        <NotificationPopupItem
          key={notification.id}
          notification={notification}
        />
      ))}
    </ul>
  );
};

export default NotificationPopupList;
