import ArbitrumIcon from "@/assets/icons/arbitrum.svg";
import EthereumIcon from "@/assets/icons/ethereum.svg";
import PolygonIcon from "@/assets/icons/polygon.svg";
import styles from "./BlockchainSwitcher.module.css";
import { Blockchain } from "@/shared/config/blockchain/types";
import { twMerge } from "tailwind-merge";
import { publicConfig } from "@/shared/config/constants";

const BLOCKCHAIN_ICONS: { [blockchain: string]: any } = {
  [Blockchain.ARBITRUM]: <ArbitrumIcon className="w-6 h-6" />,
  [Blockchain.ETHEREUM]: (
    <EthereumIcon className={twMerge(styles.ethIcon, "w-6 h-6")} />
  ),
  [Blockchain.POLYGON]: (
    <PolygonIcon className={twMerge(styles.ethIcon, "w-6 h-6")} />
  ),
};

export const BLOCKCHAINS_MENU = Object.values(publicConfig.chains).map(
  (chain) => ({
    id: chain.name,
    label: chain.label,
    icon: BLOCKCHAIN_ICONS[chain.name],
  }),
);
