"use client";
import metaMaskConnector from "./metaMask";
import walletConnectConnector from "./walletConnect";
import coinbaseConnector from "./coinbase";

const connectors = [
  metaMaskConnector,
  walletConnectConnector,
  coinbaseConnector,
];

export {
  connectors,
  metaMaskConnector,
  walletConnectConnector,
  coinbaseConnector,
};
