"use client";
import React from "react";
import styles from "./NavigationSeparator.module.css";

interface Props {
  text?: string;
}

const NavigationSeparator: React.FC<Props> = ({ text }) => {
  return <div className={styles.separator}>{text}</div>;
};

export default NavigationSeparator;
