"use client";
import { createContext, useContext } from "react";
import GuidedTourContextType from "./types";

const GuidedTourContext = createContext<GuidedTourContextType | undefined>(
  undefined,
);

export const useGuidedTourContext = () => {
  const context = useContext(GuidedTourContext);
  if (!context) {
    throw new Error("useGuidedTourContext must be used within a TourProvider");
  }
  return context;
};

export default GuidedTourContext;
